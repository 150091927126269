import React from 'react';
import { CancelIcon } from '@depop/web-ui-kit/Icons/CancelIcon';
import { H1 } from '@depop/web-ui-kit/Typography/H1';
import { ArrowBackIcon } from '@depop/web-ui-kit/Icons/ArrowBackIcon';

import styles from '../baseFilterStyles.module.css';

import { ButtonMinimal } from '@/components/ButtonMinimal';

type Props = {
  title: string;
  closeMobileFilterDrawer: () => void;
  toggleDropdown?: () => void;
};

export function MobileFilterHeader({
  title,
  closeMobileFilterDrawer,
  toggleDropdown,
}: Props) {
  /** if a toggle function is passed in, we can render a back arrow */
  const showBackButton = Boolean(toggleDropdown);

  return (
    <div className={styles.header}>
      {showBackButton && (
        <ButtonMinimal>
          {/** @TODO missing translation */}
          <ArrowBackIcon onClick={toggleDropdown} title="back" />
        </ButtonMinimal>
      )}
      <H1>{title}</H1>
      <ButtonMinimal
        onClick={closeMobileFilterDrawer}
        /** @TODO missing translation */
        title="Close filters"
      >
        <CancelIcon />
      </ButtonMinimal>
    </div>
  );
}

import { useParams, usePathname, useSearchParams } from 'next/navigation';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';

import { useActivityTracker } from '../activityTracker/useActivityTracker';
import { useCurrentUser } from '../user/useCurrentUser';
import { ActivityTrackerEventType } from '../activityTracker/constants';
import { useCategoriesByPath } from '../categories/useCategoriesByPath';
import { useBrandsById } from '../brands/hooks/useBrandsbyId';
import { buildPathParamsUrl, parseFiltersFromUrl } from '../filters/helpers';
import { useExtendedRouter } from '../routing/useExtendedRouter';
import { useCurrentLocation } from '../location/useCurrentLocation';
import { RQ_BRANDS_BATCH_BY_SLUG_KEY } from '../ReactQuery/cacheKeys';
import { fetchBrandsBatchBySlugs } from '../brands/api';

import { TSortByValues } from './types';
import { SORT_BY_OPTIONS } from './constants';
import { getIsPathParamsMode } from './helpers';
import { useCombinedFilters } from './useCombinedFilters';

export type SearchFilters = {
  category?: string;
  subcategories?: string[];
  brands?: string[];
  isDiscounted: boolean;
  priceMin?: number;
  priceMax?: number;
  sizes?: string[];
  colours?: string[];
  conditions?: string[];
  sort?: TSortByValues;
  discountTypes?: string;
  minDiscount?: string;
  maxDiscount?: string;
  shippingId?: string;
  groups?: string;
  productTypes?: string[];
  isKids?: boolean;
  gender?: string;
};

const emptyFilterState = {
  isDiscounted: false,
  sort: SORT_BY_OPTIONS[0].id,
};

function sortNumericAscending<T>(a: T, b: T) {
  return Number(a) > Number(b) ? 1 : -1;
}

function shouldTriggerBrowsePageChange({
  newFilterState,
  searchFilters,
  pathname,
  url,
  location,
}: {
  newFilterState: SearchFilters;
  searchFilters: SearchFilters;
  pathname: string;
  url: string;
  location: string;
}) {
  /*
   * There are three situations in which we should make a hard route change when in
   * path params mode.
   * 1. When we move to a different type of browse page e.g Brand -> brand + category
   * 2. When we add or remove a category within the category or brand + category page
   * 3. When we add or remove a subcategory within the category or brand + category page
   * Outside of these we should shallow route as normal for a simple filter update to
   * avoid the heavy round trip to the server when not needed
   */
  // eslint-disable-next-line prefer-destructuring
  let pagePath = pathname.split('/')[1];
  // eslint-disable-next-line prefer-destructuring
  let newPagePath = url.split('/')[1];

  if (location !== process.env.NEXT_PUBLIC_DEFAULT_LOCATION) {
    // eslint-disable-next-line prefer-destructuring
    pagePath = pathname.split('/')[2];
    // eslint-disable-next-line prefer-destructuring
    newPagePath = url.split('/')[2];
  }

  if (pagePath !== newPagePath) {
    return true;
  }

  if (
    newFilterState.category !== searchFilters.category &&
    pagePath !== 'explore'
  ) {
    return true;
  }

  if (
    JSON.stringify(newFilterState.subcategories) !==
      JSON.stringify(searchFilters.subcategories) &&
    pagePath !== 'explore'
  ) {
    return true;
  }

  return false;
}

function updateParam(
  params: URLSearchParams,
  name: string,
  value?: string[] | string
): void {
  if (!value || value.length === 0) {
    return params.delete(name);
  }
  if (value instanceof Array) {
    return params.set(name, value.join(','));
  }
  return params.set(name, value);
}

export function useSearchFilters() {
  const searchParams = useSearchParams();
  const params = useParams();
  const slug = params?.slug;
  const pathname = usePathname();
  const [, user] = useCurrentUser();
  const router = useExtendedRouter();
  const { sendActivityTrackerEvent } = useActivityTracker({ userId: user?.id });
  const isPathParamsMode = getIsPathParamsMode(pathname);
  const { data: categoriesByPath } = useCategoriesByPath({
    enabled: isPathParamsMode,
  });
  const { data: brandsById } = useBrandsById({ enabled: isPathParamsMode });
  const { data: brandsBySlugData } = useQuery({
    queryKey: [RQ_BRANDS_BATCH_BY_SLUG_KEY, { slug: [slug] }],
    queryFn: () => fetchBrandsBatchBySlugs({ brandSlugs: [slug as string] }),
    enabled: !!slug,
  });
  const [filtersModified, setFiltersModified] = useState(false);

  const { location } = useCurrentLocation();

  const filtersFromUrl = parseFiltersFromUrl({
    params: searchParams,
    pathname,
    isPathParamsMode,
    categoriesByPath: categoriesByPath?.data,
    brandData: brandsBySlugData?.data,
  });

  const searchFilters = useCombinedFilters(filtersFromUrl, filtersModified);

  function pushFilterStateToUrl(newFilterState: SearchFilters) {
    const queryParams = new URLSearchParams(Array.from(searchParams.entries()));

    const {
      isDiscounted,
      priceMin,
      priceMax,
      sizes,
      colours,
      conditions,
      sort,
      category,
      subcategories,
      brands,
    } = newFilterState;

    /** set all filters as query params by default */
    updateParam(queryParams, 'isDiscounted', isDiscounted ? 'true' : undefined);
    updateParam(queryParams, 'priceMin', priceMin?.toString());
    updateParam(queryParams, 'priceMax', priceMax?.toString());
    updateParam(queryParams, 'sizes', sizes);
    updateParam(queryParams, 'colours', colours);
    updateParam(queryParams, 'conditions', conditions);
    updateParam(queryParams, 'sort', sort);
    updateParam(queryParams, 'categories', category);
    updateParam(queryParams, 'subcategories', subcategories);
    updateParam(queryParams, 'brands', brands);

    setFiltersModified(true);

    let url = `${pathname}?${queryParams.toString()}`;

    /**
     * in path params mode, we conditionally replace some query params with
     * path segments in the URL
     */
    if (isPathParamsMode) {
      url = buildPathParamsUrl({
        queryParams,
        brands,
        category,
        subcategories,
        categoriesByPath: categoriesByPath?.data,
        brandsById: brandsById?.data,
      });

      // @TODO: JW - When the page is out to 100% and in migrated routes this url code can be removed
      url =
        location === process.env.NEXT_PUBLIC_DEFAULT_LOCATION
          ? url
          : `/${location}${url}`;

      if (
        shouldTriggerBrowsePageChange({
          newFilterState,
          searchFilters,
          pathname,
          url,
          location,
        })
      ) {
        return router.push(url);
      }
    }

    return window.history.pushState(null, '', url);
  }

  function updateMultiSelectFilter(
    name: 'brands' | 'sizes' | 'colours' | 'conditions',
    value?: string
  ): void {
    if (!value) {
      return pushFilterStateToUrl({ ...searchFilters, [name]: undefined });
    }

    const newFilterState = { ...searchFilters };
    const currentFilterValue = newFilterState[name] ?? [];

    if (currentFilterValue.includes(value)) {
      currentFilterValue.splice(currentFilterValue.indexOf(value), 1);
    } else {
      currentFilterValue.push(value);
    }

    if (currentFilterValue.length === 0) {
      delete newFilterState[name];
    } else {
      newFilterState[name] = currentFilterValue.sort(sortNumericAscending);
    }

    return pushFilterStateToUrl(newFilterState);
  }

  function setCategoryFilter(value?: string) {
    const newFilterState = { ...searchFilters };

    if (!value || value === searchFilters.category) {
      delete newFilterState.category;
      delete newFilterState.subcategories;

      return pushFilterStateToUrl(newFilterState);
    }

    newFilterState.category = value;
    delete newFilterState.subcategories;

    pushFilterStateToUrl(newFilterState);
  }

  function setSubcategoryFilter(value?: string) {
    const newFilterState = { ...searchFilters };

    if (!value) {
      delete newFilterState.subcategories;
      return pushFilterStateToUrl(newFilterState);
    }

    if (newFilterState.subcategories?.includes(value)) {
      newFilterState.subcategories = newFilterState.subcategories?.filter(
        (subcat) => subcat !== value
      );
    } else {
      newFilterState.subcategories = newFilterState.subcategories
        ? [...newFilterState.subcategories, value].sort(sortNumericAscending)
        : [value];
    }

    pushFilterStateToUrl(newFilterState);
  }

  function setBrandFilter(value?: string) {
    updateMultiSelectFilter('brands', value);
  }

  function setIsDiscountedFilter(isDiscounted: boolean) {
    pushFilterStateToUrl({ ...searchFilters, isDiscounted });
  }

  function setPriceFilter({
    priceMin,
    priceMax,
  }: {
    priceMin?: number;
    priceMax?: number;
  }) {
    const newFilterState = { ...searchFilters };

    /**
     * If priceMin or priceMax are present, set those values
     * Unset either if missing
     */
    if (priceMin && priceMax) {
      newFilterState.priceMin = priceMin;
      newFilterState.priceMax = priceMax;
    } else if (!priceMax && !priceMin) {
      delete newFilterState.priceMin;
      delete newFilterState.priceMax;
    } else if (priceMin) {
      newFilterState.priceMin = priceMin;
      delete newFilterState.priceMax;
    } else if (priceMax) {
      delete newFilterState.priceMin;
      newFilterState.priceMax = priceMax;
    }

    pushFilterStateToUrl(newFilterState);
  }

  function setSizeFilter(value?: string) {
    updateMultiSelectFilter('sizes', value);
  }

  function setColourFilter(value?: string) {
    updateMultiSelectFilter('colours', value);
  }

  function setConditionFilter(value?: string) {
    updateMultiSelectFilter('conditions', value);
  }

  function setSortFilter(sort: TSortByValues) {
    pushFilterStateToUrl({ ...searchFilters, sort });
  }

  function clearAll() {
    sendActivityTrackerEvent(ActivityTrackerEventType.CLEAR_FILTER_ACTION, {
      schemaVersion: '2.0',
    });

    pushFilterStateToUrl({ ...emptyFilterState });
  }

  return {
    searchFilters,
    setSearchFilters: {
      setCategoryFilter,
      setSubcategoryFilter,
      setBrandFilter,
      setIsDiscountedFilter,
      setPriceFilter,
      setSizeFilter,
      setColourFilter,
      setConditionFilter,
      setSortFilter,
      clearAll,
    },
  };
}

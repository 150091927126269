/* eslint-disable jsx-a11y/no-static-element-interactions */
'use client';

import React, { useRef, useState } from 'react';
import { useClickAway } from 'react-use';
import clsx from 'clsx';

import styles from './styles.module.css';

export type TriggerComponentProps = {
  isOpen: boolean;
  isActive?: boolean;
  onClick: (e?: React.MouseEvent) => void;
  disabled?: boolean;
};

type Props = {
  className?: string;
  onOpen?: () => void;
  TriggerComponent: ({
    isOpen,
    onClick,
  }: TriggerComponentProps) => React.ReactElement;
  isActive?: boolean;
  children: (props: {
    toggleDropdown: () => void;
    className: string;
  }) => React.ReactElement;
};

export function Dropdown({
  className,
  TriggerComponent,
  onOpen,
  isActive,
  children,
}: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const clickAwayRef = useRef(null);

  function toggleDropdown(e?: React.MouseEvent) {
    e?.preventDefault();
    if (!isOpen) {
      onOpen && onOpen();
    }
    setIsOpen((prevState) => !prevState);
  }

  function onKeyDown(e: React.KeyboardEvent) {
    if (e.key === 'Escape' && isOpen) {
      toggleDropdown();
    }
  }

  useClickAway(clickAwayRef, () => setIsOpen(false));

  return (
    <div
      className={clsx(styles.dropdownContainer, className)}
      ref={clickAwayRef}
      onKeyDown={onKeyDown}
    >
      <TriggerComponent
        isActive={isActive}
        isOpen={isOpen}
        onClick={toggleDropdown}
      />
      {isOpen &&
        children({ toggleDropdown, className: styles.contentContainer })}
    </div>
  );
}

import { LegacyCMSCategoryPage } from './types';

export enum CMS_LOCALES {
  en = 'en-US',
}

export const LEGACY_DEFAULT_CMS_CATEGORY_PAGE: LegacyCMSCategoryPage = {
  title: '',
  category: {
    id: 0,
    name: '',
    slug: '',
    parentId: 0,
    children: [],
  },
  copy: '',
  seo: {},
};

import { useQuery } from '@tanstack/react-query';

import { getCurrentSession } from '../activityTracker/helpers';

import { RQ_CATEGORIES_BY_ID_KEY } from '@/modules/ReactQuery/cacheKeys';
import { fetchCategoriesById } from '@/modules/categories/api';

export function useCategoriesById(enabled: boolean = true) {
  const { sessionStorageSessionId: sessionId } = getCurrentSession();

  return useQuery({
    queryKey: [RQ_CATEGORIES_BY_ID_KEY],
    queryFn: () =>
      fetchCategoriesById({
        sessionId: sessionId || '',
      }),
    enabled,
  });
}

import { useQuery } from '@tanstack/react-query';

import { RQ_CATEGORIES_BY_PATH_KEY } from '@/modules/ReactQuery/cacheKeys';
import { fetchCategoryByPathData } from '@/modules/categories/api';

export function useCategoriesByPath({ enabled }: { enabled: boolean }) {
  return useQuery({
    queryKey: [RQ_CATEGORIES_BY_PATH_KEY],
    queryFn: fetchCategoryByPathData,
    enabled,
  });
}

import { useQuery } from '@tanstack/react-query';

import { RQ_SIZE_FILTER_TREE } from '../ReactQuery/cacheKeys';

import { fetchSizeFilterTree } from './api';

export function useSizeFilters() {
  return useQuery({
    queryKey: [RQ_SIZE_FILTER_TREE],
    queryFn: () => fetchSizeFilterTree(),
    refetchOnMount: false,
  });
}

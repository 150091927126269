import { LegacyCategoryMappingValue } from '../productAttributes/types';

import { WOMENS_CATEGORY_ID, MENS_CATEGORY_ID } from './../filters/constants';

import { SupportedLocation } from '@/modules/location/constants.ts';

export const kidswearId = 'kidswear';
export const everythingElseId = 'everything-else';

export const GENDER_TO_DEPARTMENT_ID: Record<string, string> = {
  male: 'menswear',
  female: 'womenswear',
};

export const DEPARTMENT_TO_CATEGORY_ID: Record<
  string,
  keyof LegacyCategoryMappingValue
> = {
  menswear: 'maleId',
  womenswear: 'femaleId',
  kidswear: 'kidsId',
};

export const CATEGORY_PATH_TO_GENDER: Record<string, string> = {
  mens: 'male',
  womens: 'female',
};

export const GENDER_TO_CATEGORY_PATH: Record<string, string> = {
  male: 'mens',
  female: 'womens',
};

export const RESTRICTED_SUBCATEGORY_NAMES = [
  'Other',
  'Lingerie',
  'Sets',
  'Underwear',
];
export const MAX_POPULAR_CATEGORIES = 7;

export const WW_CATEGORY_BUBBLES_CONFIG = [
  {
    label: 'Tops',
    url: 'category/womens/tops/',
    imgSrc: `${process.env.NEXT_PUBLIC_DEPOP_CLOUDFRONT_HOST}/web/assets/category/WW-Tops.jpeg`,
  },
  {
    label: 'Bottoms',
    url: 'category/womens/bottoms',
    imgSrc: `${process.env.NEXT_PUBLIC_DEPOP_CLOUDFRONT_HOST}/web/assets/category/WW-Bottoms.jpeg`,
  },
  {
    label: 'Dresses',
    url: 'category/womens/dresses/',
    imgSrc: `${process.env.NEXT_PUBLIC_DEPOP_CLOUDFRONT_HOST}/web/assets/category/WW-Dresses.jpeg`,
  },
  {
    label: 'Outerwear',
    url: 'category/womens/outerwear/',
    imgSrc: `${process.env.NEXT_PUBLIC_DEPOP_CLOUDFRONT_HOST}/web/assets/category/WW-Outerwear.jpeg`,
  },
  {
    label: 'Sneakers',
    url: 'category/womens/shoes/sneakers/',
    imgSrc: `${process.env.NEXT_PUBLIC_DEPOP_CLOUDFRONT_HOST}/web/assets/category/WW-Sneakers.jpeg`,
  },
  {
    label: 'Accessories',
    url: 'category/womens/accessories/',
    imgSrc: `${process.env.NEXT_PUBLIC_DEPOP_CLOUDFRONT_HOST}/web/assets/category/MWW-Accessories.jpeg`,
  },
];

export function getMWCategoryBubblesConfig(locale?: string) {
  return [
    {
      label: 'T-Shirts',
      url: 'category/mens/tops/t-shirts/',
      imgSrc: `${process.env.NEXT_PUBLIC_DEPOP_CLOUDFRONT_HOST}/web/assets/category/MW-Tshirt.jpeg`,
    },
    {
      label: locale === SupportedLocation.GB ? 'Jumpers' : 'Sweaters',
      url: 'category/mens/tops/sweaters/',
      imgSrc: `${process.env.NEXT_PUBLIC_DEPOP_CLOUDFRONT_HOST}/web/assets/category/MW-Sweaters.jpg`,
    },
    {
      label: 'Jeans',
      url: 'category/mens/bottoms/jeans/',
      imgSrc: `${process.env.NEXT_PUBLIC_DEPOP_CLOUDFRONT_HOST}/web/assets/category/MW-Jeans.jpeg`,
    },
    {
      label: 'Outerwear',
      url: 'category/mens/outerwear/',
      imgSrc: `${process.env.NEXT_PUBLIC_DEPOP_CLOUDFRONT_HOST}/web/assets/category/MW-Outerwear.jpeg`,
    },
    {
      label: 'Sneakers',
      url: 'category/mens/shoes/sneakers/',
      imgSrc: `${process.env.NEXT_PUBLIC_DEPOP_CLOUDFRONT_HOST}/web/assets/category/MW-Sneakers.jpeg`,
    },
    {
      label: 'Accessories',
      url: 'category/mens/accessories/',
      imgSrc: `${process.env.NEXT_PUBLIC_DEPOP_CLOUDFRONT_HOST}/web/assets/category/MWW-Accessories.jpeg`,
    },
  ];
}

export function getBubblesConfigToCategoryId(locale?: SupportedLocation) {
  return {
    [WOMENS_CATEGORY_ID]: WW_CATEGORY_BUBBLES_CONFIG,
    [MENS_CATEGORY_ID]: getMWCategoryBubblesConfig(locale),
  };
}

import { createCombinedFilters } from '../cms/helpers/createCombinedSearchFilters';

import { SearchFilters } from './useSearchFilters';

import { useCmsExploreFilters } from '@/modules/search/useCmsExploreFilters';

export function useCombinedFilters(
  filtersFromUrl: SearchFilters,
  filtersModified: boolean
) {
  const { filters: cmsExploreFilters } = useCmsExploreFilters();

  if (cmsExploreFilters) {
    return createCombinedFilters(
      filtersFromUrl,
      cmsExploreFilters,
      filtersModified
    );
  }
  return filtersFromUrl;
}

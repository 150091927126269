import { useEffect, useState } from 'react';

import {
  hasActiveFilters,
  transformFiltersToActivityTrackerPayload,
} from './helpers';

import { useSearchFilters } from '@/modules/search/useSearchFilters';
import { useActivityTracker } from '@/modules/activityTracker/useActivityTracker';
import { useCurrentUser } from '@/modules/user/useCurrentUser';
import { ActivityTrackerEventType } from '@/modules/activityTracker/constants';

export function useFiltersTracking() {
  const [, user] = useCurrentUser();
  const { searchFilters } = useSearchFilters();
  const { sendActivityTrackerEvent } = useActivityTracker({ userId: user?.id });
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const { sort, ...filtersExceptSort } = searchFilters;

  useEffect(() => {
    if (!isInitialLoad && hasActiveFilters(filtersExceptSort)) {
      sendActivityTrackerEvent(
        ActivityTrackerEventType.FILTER_APPLIED_ACTION,
        transformFiltersToActivityTrackerPayload(searchFilters)
      );
    }
  }, [JSON.stringify(filtersExceptSort)]);

  useEffect(() => {
    if (!isInitialLoad && searchFilters.sort) {
      sendActivityTrackerEvent(ActivityTrackerEventType.SORT_APPLIED_ACTION, {
        sorting: searchFilters.sort,
        defaultSorting: searchFilters.sort === 'relevance',
      });
    }
  }, [searchFilters.sort]);

  /* Do not send for the initial filter state, i.e before a new filter has
  been applied by the user */
  useEffect(() => {
    setIsInitialLoad(false);
  }, []);
}

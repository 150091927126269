import http from '../http';
import { AxiosCompatibleResponse } from '../http/types';
import {
  LegacyCategoryFilters,
  SizeFilters,
  SubcategoryFilters,
} from '../filters/types';

import {
  LEGACY_CATEGORY_FILTERS,
  SIZE_FILTER_TREE,
} from '@/constants/endpoints';

export function fetchSizeFilterTree(): Promise<
  AxiosCompatibleResponse<SizeFilters>
> {
  return http.get(SIZE_FILTER_TREE, {
    cache: 'force-cache',
  });
}

export function fetchLegacyCategoryFilters(): Promise<
  AxiosCompatibleResponse<{
    categoryFilters: LegacyCategoryFilters;
    subcategoryFilterDictionary: SubcategoryFilters;
  }>
> {
  return http.get(LEGACY_CATEGORY_FILTERS, {
    cache: 'force-cache',
  });
}

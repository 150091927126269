import { isProduction } from '@/modules/env/isProduction';

export function createCMSExploreQuery(slug: string) {
  return `query webExplorePageResultsCollectionQuery {
      webExplorePageResultsCollection(where: {slug: "${slug}"}, limit: 1, preview: ${!isProduction()}) {
        items {
          heading
          slug
          description
          searchTerm
          extendedSearchTerm
          desktopBackgroundImage {
            url
            width
            height
          }
          mobileBackgroundImage {
            url
            width
            height
          }
          cta {
            copy
            url
          }
          hideCategoryFilters
          brands {
            brandsCollection {
              items {
                brand
              }
            }
          }
          categorySizeRestrictions {
            categoriesCollection {
              items {
                category
              }
            }
          }
          headerModule {
            itemsCollection {
              items {
                title
                link
                extendedLink
                image {
                  url
                }
              }
            }
          }
          featuredShops {
            title
            showTitle
            shops
          }
          seoIndex
          seoMetaTitle
          seoMetaDescription
        }
      }
    }`;
}

import { useMemo } from 'react';

import { useBrandsById } from '../brands/hooks/useBrandsbyId';
import { buildPopularBrands } from '../brands/utils';
import { MAX_POPULAR_BRANDS } from '../brands/constants';
import { useSearchFilterAggregates } from '../search/useSearchFilterAggregates';

import { getFilteredBrands } from './brandFilterUtils';

export function useBrandsList({
  searchInputValue,
  sellerId,
}: {
  searchInputValue: string;
  sellerId?: number;
}): Array<{
  id?: number;
  name: string;
  slug?: string;
  count?: number;
  isHeading?: boolean;
}> {
  const { data: aggregates } = useSearchFilterAggregates({
    sellerId,
  });
  const { data: brandData, isLoading: isBrandDataLoading } = useBrandsById();

  const brands = brandData?.data;
  const brandAggregates = aggregates?.data.brands;

  const suggestedBrands = useMemo(() => {
    if (!brands || searchInputValue || !brandAggregates) {
      return [];
    }
    const popularBrands = buildPopularBrands(
      brands,
      brandAggregates,
      MAX_POPULAR_BRANDS
    );

    if (!popularBrands.length) {
      return [];
    }

    return [
      {
        isHeading: true,
        name: 'TopResults',
      },
      ...popularBrands,
    ];
  }, [searchInputValue, isBrandDataLoading, brandAggregates]);

  const aToZBrands = useMemo(() => {
    if (!brands) {
      return [];
    }
    if (searchInputValue) {
      return getFilteredBrands(brands, searchInputValue);
    }
    return [
      { isHeading: true, name: 'Alphabetical' },
      ...Object.values(brands).sort((a, b) => a.name.localeCompare(b.name)),
    ];
  }, [searchInputValue, isBrandDataLoading]);

  return [...suggestedBrands, ...aToZBrands];
}

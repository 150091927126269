import React from 'react';
import { useTranslations } from 'next-intl';
import { CheckboxWithLabel } from '@depop/web-ui-kit/CheckboxWithLabel';
import clsx from 'clsx';

import { MobileFilterFooter } from '../MobileFilterFooter';
import { MobileFilterHeader } from '../MobileFilterHeader';
import { SearchFilterTrigger } from '../SearchFilterTrigger';
import baseFilterStyles from '../baseFilterStyles.module.css';

import styles from './styles.module.css';

import { useSearchFilters } from '@/modules/search/useSearchFilters';
import { Dropdown } from '@/components/Dropdown';
import { useLegacyCategoryFilters } from '@/modules/filters/useLegacyCategoryFilters';
import { useActivityTracker } from '@/modules/activityTracker/useActivityTracker';
import { FilterTypes } from '@/modules/filters/types';
import { useCurrentUser } from '@/modules/user/useCurrentUser';
import { ActivityTrackerEventType } from '@/modules/activityTracker/constants';
import { translateCategory } from '@/modules/filters/helpers';

type Props = {
  closeMobileFilterDrawer: () => void;
  sellerId?: number;
};

export function SubcategoryFilter({
  closeMobileFilterDrawer,
  sellerId,
}: Props) {
  const { searchFilters, setSearchFilters } = useSearchFilters();
  const t = useTranslations('common');
  const title = t('Subcategory');
  const { data } = useLegacyCategoryFilters();
  const [, user] = useCurrentUser();
  const { sendActivityTrackerEvent } = useActivityTracker({ userId: user?.id });

  if (!data || !searchFilters.category) {
    return null;
  }

  const subcategoryOptions =
    data?.data.subcategoryFilterDictionary[searchFilters.category];

  if (!subcategoryOptions) {
    return null;
  }

  return (
    <Dropdown
      TriggerComponent={(props) => (
        <SearchFilterTrigger
          {...props}
          isActive={
            searchFilters.subcategories &&
            searchFilters.subcategories.length > 0
          }
          title={title}
        />
      )}
      onOpen={() =>
        sendActivityTrackerEvent(
          ActivityTrackerEventType.FILTER_OPENED_ACTION,
          { filterType: FilterTypes.Subcategory }
        )
      }
    >
      {({ className, toggleDropdown }) => {
        return (
          <div
            className={clsx(
              className,
              baseFilterStyles.searchFilterDropdownContents,
              styles.categoryFilterDropdownContents
            )}
          >
            <MobileFilterHeader
              title={title}
              toggleDropdown={toggleDropdown}
              closeMobileFilterDrawer={closeMobileFilterDrawer}
            />
            <div className={baseFilterStyles.content}>
              {subcategoryOptions.map(({ id, name }) => (
                <CheckboxWithLabel
                  className={clsx(
                    baseFilterStyles.searchFilterCheckbox,
                    styles.subcategoryCheckbox
                  )}
                  key={name}
                  label={translateCategory(t, name)}
                  align="left"
                  value={`category-${id}`}
                  onChange={() =>
                    setSearchFilters.setSubcategoryFilter(String(id))
                  }
                  checked={searchFilters.subcategories?.includes(String(id))}
                />
              ))}
            </div>
            <MobileFilterFooter
              handleSubmit={closeMobileFilterDrawer}
              handleClear={() => setSearchFilters.setSubcategoryFilter()}
              sellerId={sellerId}
            />
          </div>
        );
      }}
    </Dropdown>
  );
}

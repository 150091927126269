import { useQuery } from '@tanstack/react-query';

import { RQ_LEGACY_SEARCH_CATEGORY_FILTERS } from '../ReactQuery/cacheKeys';

import { fetchLegacyCategoryFilters } from './api';

export function useLegacyCategoryFilters() {
  return useQuery({
    queryKey: [RQ_LEGACY_SEARCH_CATEGORY_FILTERS],
    queryFn: fetchLegacyCategoryFilters,
  });
}

import { queryOptions } from '@tanstack/react-query';

import { RQ_CMS_EXPLORE_PAGE } from '../ReactQuery/cacheKeys';

import { getContentfulData } from './api';
import { CMSExplorePageResponse } from './types';
import { createCMSExploreQuery } from './helpers/createCMSExplorePageQuery';
import { transformCMSExplorePageResponse } from './helpers/transformCMSExplorePageResponse.ts';

export function cmsExplorePageQueryOptions(slug: string, enabled = true) {
  const query = createCMSExploreQuery(slug);
  return queryOptions({
    queryKey: [RQ_CMS_EXPLORE_PAGE, slug],
    queryFn: async () => {
      const { data } = await getContentfulData<CMSExplorePageResponse>({
        query,
      });
      return transformCMSExplorePageResponse(data);
    },
    enabled,
  });
}

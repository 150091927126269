import { useQuery } from '@tanstack/react-query';
import { useParams, usePathname } from 'next/navigation';

import { cmsExplorePageQueryOptions } from '@/modules/cms/queries';
import { parseFilterParams } from '@/modules/filters/helpers';

export function useCmsExploreFilters() {
  const pathname = usePathname();
  const { slug = '' } = useParams<{ slug: string }>();
  const isCmsExplorePage = Boolean(
    pathname.match(/^\/(?:([a-z]{2})\/)?explore\/([-_a-z0-9]+)/)
  );
  const { data } = useQuery(cmsExplorePageQueryOptions(slug, isCmsExplorePage));

  if (!isCmsExplorePage || !data) {
    return {};
  }

  const params = new URLSearchParams(data.searchTerm);

  return {
    filters: parseFilterParams(params),
    searchInput: params.get('q') || undefined,
  };
}

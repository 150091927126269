import { useTranslations } from 'next-intl';

import { getSubcategoryNames } from './helpers';
import { useCategoriesById } from './useCategoriesById';

type UseSubcategoryNamesProps = {
  subcategoryIds?: string[];
};

export function useSubcategoryNames({
  subcategoryIds,
}: UseSubcategoryNamesProps) {
  const t = useTranslations('common');
  const hasSubcategories = !!subcategoryIds?.length;

  const { data } = useCategoriesById(hasSubcategories);

  const subcategoryNames = getSubcategoryNames({
    categoriesById: data?.data,
    subcategoryIds: hasSubcategories ? subcategoryIds : [],
  });

  return subcategoryNames.map((name) => t(name));
}

import { useTranslations } from 'next-intl';
import React from 'react';
import clsx from 'clsx';
import { CheckboxWithLabel } from '@depop/web-ui-kit/CheckboxWithLabel';

import { SearchFilterTrigger } from '../SearchFilterTrigger';
import baseFilterStyles from '../baseFilterStyles.module.css';
import { MobileFilterHeader } from '../MobileFilterHeader';
import { MobileFilterFooter } from '../MobileFilterFooter';
import { CollapsibleRow } from '../SizeFilter/CollapsibleRow';

import styles from './styles.module.css';

import { Dropdown } from '@/components/Dropdown';
import { useSearchFilters } from '@/modules/search/useSearchFilters';
import { useLegacyCategoryFilters } from '@/modules/filters/useLegacyCategoryFilters';
import { FilterTypes } from '@/modules/filters/types';
import { useActivityTracker } from '@/modules/activityTracker/useActivityTracker';
import { useCurrentUser } from '@/modules/user/useCurrentUser';
import { ActivityTrackerEventType } from '@/modules/activityTracker/constants';
import { translateCategory } from '@/modules/filters/helpers';

type Props = {
  closeMobileFilterDrawer: () => void;
  sellerId?: number;
};

export function CategoryFilter({ closeMobileFilterDrawer, sellerId }: Props) {
  const t = useTranslations('common');
  const { searchFilters, setSearchFilters } = useSearchFilters();
  const { data } = useLegacyCategoryFilters();
  const [, user] = useCurrentUser();
  const { sendActivityTrackerEvent } = useActivityTracker({ userId: user?.id });

  const title = t('Category');

  return (
    <Dropdown
      TriggerComponent={(props) => (
        <SearchFilterTrigger
          {...props}
          isActive={Boolean(searchFilters.category)}
          title={title}
        />
      )}
      onOpen={() =>
        sendActivityTrackerEvent(
          ActivityTrackerEventType.FILTER_OPENED_ACTION,
          { filterType: FilterTypes.Category }
        )
      }
    >
      {({ className, toggleDropdown }) => {
        return (
          <div
            className={clsx(
              className,
              baseFilterStyles.searchFilterDropdownContents,
              styles.categoryFilterDropdownContents
            )}
          >
            <MobileFilterHeader
              title={title}
              toggleDropdown={toggleDropdown}
              closeMobileFilterDrawer={closeMobileFilterDrawer}
            />
            <div className={baseFilterStyles.content}>
              {data?.data.categoryFilters.map(({ name, children }) => {
                return (
                  <CollapsibleRow
                    key={`accordion-${name}`}
                    label={translateCategory(t, name) || name}
                  >
                    {children?.map(({ id, name }) => (
                      <CheckboxWithLabel
                        className={clsx(
                          baseFilterStyles.searchFilterCheckbox,
                          styles.categoryCheckbox
                        )}
                        key={name}
                        label={translateCategory(t, name)}
                        align="left"
                        value={`category-${id}`}
                        onChange={() =>
                          setSearchFilters.setCategoryFilter(String(id))
                        }
                        checked={String(id) === searchFilters.category}
                      />
                    ))}
                  </CollapsibleRow>
                );
              })}
            </div>
            <MobileFilterFooter
              handleSubmit={closeMobileFilterDrawer}
              handleClear={() => setSearchFilters.setCategoryFilter()}
              sellerId={sellerId}
            />
          </div>
        );
      }}
    </Dropdown>
  );
}

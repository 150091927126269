import { useRef } from 'react';
import { useQuery, UseQueryResult } from '@tanstack/react-query';

import {
  RQ_FILTER_AGGREGATES_KEY,
  RQ_FILTER_AGGREGATES_SHOP_KEY,
} from '../ReactQuery/cacheKeys';
import { useSessionId } from '../storage/useSessionId';
import { useCurrentUser } from '../user/useCurrentUser';
import { useCurrentLocation } from '../location/useCurrentLocation';
import { fetchFilterAggregates as fetchShopFilterAggregates } from '../shop/api';

import { fetchFilterAggregates as fetchSearchFilterAggregates } from './api';
import {
  buildSearchQueryParams,
  buildShopFilterAggregatesSearchQueryParams,
} from './helpers';
import { useSearchFilters } from './useSearchFilters';
import { useSearchInput } from './useSearchInput';

import { useSubcategoryNames } from '@/modules/categories/hooks';
import { FetchFilterAggregatesResponse as ShopFetchFilterAggregatesResponse } from '@/modules/shop/types.ts';
import { AxiosCompatibleResponse } from '@/modules/http/types.ts';
import { FetchFilterAggregatesResponse as SearchFetchFilterAggregatesResponse } from '@/modules/search/types.ts';

/**
 * Hook to fetch filter aggregates data. Optimistically returns
 * the previous result if the search term has not changed to
 * help prevent layout shift.
 *
 * When a sellerId param is supplied, we make a request to the shop aggregates endpoint.
 * Otherwise, the search aggregates endpoint is used.
 */
export function useSearchFilterAggregates({ sellerId }: { sellerId?: number }) {
  const sessionId = useSessionId();
  const [, user] = useCurrentUser();
  const { location } = useCurrentLocation();
  const { searchFilters } = useSearchFilters();
  const { searchInput } = useSearchInput();
  const subcategoryNames = useSubcategoryNames({
    subcategoryIds: searchFilters.subcategories,
  });
  const isShopSearch = Boolean(sellerId);

  const previousResult = useRef<{
    searchInput: string | null;
    data: UseQueryResult<
      AxiosCompatibleResponse<
        SearchFetchFilterAggregatesResponse | ShopFetchFilterAggregatesResponse
      >,
      unknown
    > | null;
  }>({ searchInput: null, data: null });

  const queryKey = [
    isShopSearch ? RQ_FILTER_AGGREGATES_SHOP_KEY : RQ_FILTER_AGGREGATES_KEY,
    { location, searchFilters },
  ];

  function queryFn() {
    const queryParamsInitialPayload = {
      searchInput,
      searchFilters,
      userId: user?.id,
      location,
      includeBoostedListings: true,
      subcategoryNames,
    };

    if (isShopSearch) {
      return fetchShopFilterAggregates({
        sellerId: sellerId ?? '',
        params: buildShopFilterAggregatesSearchQueryParams(
          queryParamsInitialPayload
        ),
      });
    }
    return fetchSearchFilterAggregates({
      params: buildSearchQueryParams(queryParamsInitialPayload),
      meta: { sessionId: sessionId ?? '' },
    });
  }

  const queryData = useQuery({
    queryKey,
    queryFn,
  });

  /**
   * We retain the state of the previous result in order to
   * provide an optimistic result whilst the new aggregate query
   * is fetching.
   *
   * If the search input term has changed or if no previous
   * result has yet been cached, return the query as-is,
   * otherwise optimistically return the previous result.
   */
  if (!queryData.isLoading) {
    previousResult.current = {
      searchInput,
      data: queryData,
    };
    return queryData;
  }

  if (
    previousResult.current.searchInput !== searchInput ||
    !previousResult.current.data
  ) {
    return queryData;
  }

  return previousResult.current.data;
}

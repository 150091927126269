import { useSearchParams } from 'next/navigation';

import { useCmsExploreFilters } from './useCmsExploreFilters';

export function useSearchInput() {
  const searchParams = useSearchParams();
  const { searchInput: cmsExploreSearchQuery } = useCmsExploreFilters();

  return {
    searchInput: cmsExploreSearchQuery || searchParams.get('q') || '',
  };
}

import _truncate from 'lodash/truncate';

const DEFAULT_TRUNCATION_LENGTH = 160;

export function truncate(
  content: string,
  length = DEFAULT_TRUNCATION_LENGTH,
  separator = ' '
) {
  if (content.length < length) {
    return content;
  }
  return _truncate(content, {
    length,
    separator,
  });
}

import { BrandByIdResponse } from '../brands/types';

export function stripDiacritics(str: string) {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export function getFilteredBrands(
  brandData: BrandByIdResponse,
  searchValue: string
) {
  const searchRegex = new RegExp(
    searchValue.replace(/[.*+?^=!:${}()|\[\]\/\\]/g, '\\$&'),
    'i'
  );

  const filteredOptions = Object.values(brandData).filter((brand) => {
    return searchRegex.test(stripDiacritics(brand.name).trim());
  });

  return filteredOptions;
}

import { useQuery } from '@tanstack/react-query';

import { fetchBrandsById } from '../api';

import { RQ_BRANDS_BY_ID_KEY } from '@/modules/ReactQuery/cacheKeys';

export function useBrandsById({ enabled } = { enabled: true }) {
  return useQuery({
    queryKey: [RQ_BRANDS_BY_ID_KEY],
    queryFn: fetchBrandsById,
    staleTime: Infinity,
    refetchOnMount: false,
    enabled,
  });
}

import {
  CMSExplorePageResponse,
  CMSExplorePage,
  CMSExplorePageBubbleNavItem,
} from '@/modules/cms/types.ts';
import { isNotNull } from '@/modules/cms/helpers/isNotNull.ts';

export function transformCMSExplorePageResponse(
  response: CMSExplorePageResponse
): CMSExplorePage {
  const [unmodifiedData] =
    response.data.webExplorePageResultsCollection.items.filter(isNotNull);
  const data = { ...unmodifiedData };

  if (unmodifiedData?.extendedSearchTerm) {
    data.searchTerm = unmodifiedData.extendedSearchTerm;
  }

  if (
    unmodifiedData?.headerModule?.itemsCollection?.items?.length &&
    data.headerModule?.itemsCollection?.items?.length
  ) {
    data.headerModule.itemsCollection.items =
      unmodifiedData.headerModule.itemsCollection.items.map(
        (item: CMSExplorePageBubbleNavItem) => ({
          title: item.title,
          image: item.image,
          link: item.extendedLink || item.link,
        })
      ) || [];
  }
  return data;
}

import http from '@/modules/http';
import { AxiosCompatibleResponse } from '@/modules/http/types';
import {
  SHOP_BY_USERNAME_ENDPOINT,
  SHOP_REARRANGE_PRODUCTS,
  SHOP_FILTER_AGGREGATES,
  SEARCH_SHOP_PRODUCTS_BY_USER_ID_ENDPOINT,
  SHOP_PRODUCTS_BY_USER_ID_V2_ENDPOINT,
  SOLD_SHOP_PRODUCTS_V2_ENDPOINT,
  ACTIVE_SHOP_PRODUCTS_V2_ENDPOINT,
  SHOP_BY_ID_ENDPOINT,
  BATCH_SHOP_BY_USERNAME_ENDPOINT,
  SHOP_PRODUCTS_BY_USER_ID_V3_ENDPOINT,
} from '@/constants/endpoints';
import {
  ShopByUsernameResponse,
  ShopProductsResponse,
  ShopProductsRequest,
  ShopProductsV3Request,
  ShopProductsV3Response,
  RearrangeProductsRequest,
  FetchFilterAggregatesResponse,
  ShopProductsSearchRequest,
  ShopProductsSearchResponse,
  ShopSearchFilterAggregateParams,
  FetchBatchShopByUsernameResponse,
} from '@/modules/shop/types';

export function fetchShopByUsername(
  username: string
): Promise<AxiosCompatibleResponse<ShopByUsernameResponse>> {
  return http.get(SHOP_BY_USERNAME_ENDPOINT.replace(':username', username));
}

export function fetchShop(
  userId: string
): Promise<AxiosCompatibleResponse<ShopByUsernameResponse>> {
  return http.get(SHOP_BY_ID_ENDPOINT.replace(':userId', userId));
}

export function fetchBatchShopByUsername(
  usernames: string[],
  includeProducts = false
): Promise<AxiosCompatibleResponse<FetchBatchShopByUsernameResponse>> {
  const usernameList = usernames.map((username) => username.trim()).join(',');

  return http.get(
    BATCH_SHOP_BY_USERNAME_ENDPOINT.replace(':usernames', usernameList),
    {
      params: {
        includeProducts: includeProducts ? 'true' : 'false',
      },
    }
  );
}

type FetchProductsParams = {
  userId: string | number;
  req: ShopProductsRequest;
  targetProductId?: number;
};

export async function fetchProducts({
  userId,
  req,
  targetProductId,
}: FetchProductsParams): Promise<ShopProductsResponse> {
  const {
    params: { brand_ids, limit = 24 },
  } = req;
  const fallbackMeta = {
    limit,
    last_offset_id: '',
    end: true,
  };

  const filteredByBrandRequest = brand_ids
    ? http.get<ShopProductsResponse>(
        SHOP_PRODUCTS_BY_USER_ID_V2_ENDPOINT.replace(':userId', String(userId)),
        {
          params: {
            ...req.params,
            limit: 96,
            on_sale: false,
          },
          withAuth: true,
        }
      )
    : Promise.resolve();

  const unfilteredRequest = http.get<ShopProductsResponse>(
    SHOP_PRODUCTS_BY_USER_ID_V2_ENDPOINT.replace(':userId', String(userId)),
    {
      params: {
        ...req.params,
        brand_ids: undefined,
        on_sale: false,
      },
      withAuth: true,
    }
  );

  const [filteredByBrandResponse, unfilteredResponse] =
    await Promise.allSettled([filteredByBrandRequest, unfilteredRequest]).then(
      (responses) =>
        responses.map((response) =>
          response.status === 'fulfilled' ? response.value : undefined
        )
    );

  if (!unfilteredResponse) {
    return Promise.reject();
  }

  const products = [
    ...(filteredByBrandResponse?.data.products || []),
    ...unfilteredResponse?.data.products,
  ];

  if (targetProductId && filteredByBrandResponse) {
    const targetProductIndex = products.findIndex(
      (product) => product.id === targetProductId
    );

    if (targetProductIndex !== -1) {
      const [targetProduct] = products.splice(targetProductIndex, 1);
      products.unshift(targetProduct);
    }
  }

  return {
    meta: unfilteredResponse?.data.meta || fallbackMeta,
    products,
  };
}

export function fetchShopProductsV3(
  userId: string | number,
  req?: ShopProductsV3Request
): Promise<AxiosCompatibleResponse<ShopProductsV3Response>> {
  return http.get(
    SHOP_PRODUCTS_BY_USER_ID_V3_ENDPOINT.replace(':userId', String(userId)),
    {
      params: req?.params,
      withAuth: true,
    }
  );
}

export function fetchSearchProducts(
  userId: string | number,
  req?: ShopProductsSearchRequest
): Promise<AxiosCompatibleResponse<ShopProductsSearchResponse>> {
  return http.get(
    SEARCH_SHOP_PRODUCTS_BY_USER_ID_ENDPOINT.replace(':userId', String(userId)),
    {
      params: req?.params,
      withAuth: true,
    }
  );
}

export function fetchFilterAggregates({
  sellerId,
  params,
}: {
  sellerId: string | number;
  params: ShopSearchFilterAggregateParams;
}): Promise<AxiosCompatibleResponse<FetchFilterAggregatesResponse>> {
  return http.get(
    SHOP_FILTER_AGGREGATES.replace(':sellerId', String(sellerId)),
    {
      params,
      withAuth: true,
    }
  );
}

export function fetchSoldShopProducts(
  userId: number | string,
  params: Record<string, unknown>
): Promise<AxiosCompatibleResponse<ShopProductsResponse>> {
  return http.get(
    SOLD_SHOP_PRODUCTS_V2_ENDPOINT.replace(':userId', String(userId)),
    {
      withAuth: true,
      params,
    }
  );
}

export function fetchActiveShopProducts(
  userId: number | string,
  params: Record<string, unknown>
): Promise<AxiosCompatibleResponse<ShopProductsResponse>> {
  return http.get(
    ACTIVE_SHOP_PRODUCTS_V2_ENDPOINT.replace(':userId', String(userId)),
    {
      withAuth: true,
      params,
    }
  );
}

export function rearrangeProducts(
  userId: number,
  params: RearrangeProductsRequest
) {
  return http.patch(
    SHOP_REARRANGE_PRODUCTS.replace(':userId', String(userId)),
    params,
    {
      withAuth: true,
    }
  );
}

import { CMSBrandCategoryPage } from '@/modules/cms/types';
import { capitalise } from '@/modules/text/helpers';
import { CMS_LOCALES } from '@/modules/cms/constants';

export function getPageTitle({
  pageMetadata,
  categories,
  brandSlug,
  includeBrand = false,
  locale,
}: {
  pageMetadata?: Partial<CMSBrandCategoryPage>;
  categories: string[];
  brandSlug?: string;
  includeBrand?: boolean;
  locale: CMS_LOCALES;
}) {
  if (pageMetadata?.title) {
    return pageMetadata?.title;
  }

  const department = locale.includes('en')
    ? `${capitalise(categories[0])} `
    : '';
  const departmentInPossessiveForm = locale.includes('en')
    ? department.slice(0, department.length - 2) + `'s `
    : '';

  const activeCategoryName = categories[categories.length - 1];

  if (includeBrand) {
    if (pageMetadata?.brand?.name && pageMetadata?.category?.name) {
      return `${department}${pageMetadata?.brand.name} ${pageMetadata?.category.name}`;
    }

    return `${department}${capitalise(brandSlug)} ${capitalise(
      activeCategoryName
    )}`;
  }

  if (pageMetadata?.category?.name) {
    return departmentInPossessiveForm + pageMetadata?.category.name;
  }

  return departmentInPossessiveForm + capitalise(activeCategoryName);
}

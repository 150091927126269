import { getTranslations } from 'next-intl/server';
import { pascalCase } from 'text-pascal-case';

import { CategoriesById, Category } from './types';
import { getBubblesConfigToCategoryId } from './constants';

import {
  CMS_LOCALES,
  LEGACY_DEFAULT_CMS_CATEGORY_PAGE,
} from '@/modules/cms/constants';
import {
  CMSBrandCategoryPage,
  LegacyCMSCategoryPage,
} from '@/modules/cms/types';
import { truncate } from '@/modules/text/truncate';
import { getPageTitle } from '@/modules/browse/helpers';

export async function getCategoryPageHeadMetadata(
  categoryPageMetadata: LegacyCMSCategoryPage,
  categoryPath: string,
  locale: string
) {
  const t = await getTranslations({ locale, namespace: 'common' });

  const { title, seo, copy = '', category } = categoryPageMetadata;
  const truncatedCopy = truncate(copy);

  return {
    title: seo?.metaTitle || title || category?.name || 'Explore',
    description:
      seo?.metaDescription ||
      truncatedCopy ||
      t('MetaDescr.Default', { categoryName: category?.name }),
    path: seo?.canonicalLink || `category/${categoryPath}`,
  };
}

export async function getBrandCategoryPageHeadMetadata(
  categoryPageMetadata: CMSBrandCategoryPage,
  categoryPath: string,
  locale: string
) {
  const t = await getTranslations({ locale, namespace: 'common' });

  const { title, seo, copy = '', category, brand } = categoryPageMetadata;
  const truncatedCopy = truncate(copy);

  return {
    title: seo?.metaTitle || title || category?.name || 'Explore',
    description:
      seo?.metaDescription ||
      truncatedCopy ||
      t('MetaDescr.Default', { categoryName: category?.name }),
    path: seo?.canonicalLink || `brands/${brand?.slug}/c/${categoryPath}`,
  };
}

export function getPageHeaderText(
  categories: string[],
  locale: CMS_LOCALES,
  categoryPageMetadata?: LegacyCMSCategoryPage
) {
  const { copy } = categoryPageMetadata || LEGACY_DEFAULT_CMS_CATEGORY_PAGE;

  const title = getPageTitle({
    pageMetadata: categoryPageMetadata,
    categories,
    locale,
  });

  return {
    title,
    description: copy,
  };
}

export function getCategoryStack(
  id: number,
  categoriesById: CategoriesById
): Category[] {
  let pointer = categoriesById[id];
  const categories = [pointer];
  while (pointer?.parentId) {
    pointer = categoriesById[pointer.parentId];
    categories.push(pointer);
  }
  categories.reverse();
  return categories;
}

export function getSubcategoryNames({
  categoriesById,
  subcategoryIds,
}: {
  subcategoryIds?: string[];
  categoriesById?: CategoriesById;
}) {
  if (!categoriesById || !subcategoryIds?.length) {
    return [];
  }

  return subcategoryIds.reduce<string[]>((acc, subcategoryId) => {
    const name = categoriesById[Number(subcategoryId)]?.name;
    if (name) {
      acc.push(pascalCase(name));
    }
    return acc;
  }, []);
}

export function isWwMwCategoryBubbleId(
  categoryId?: string
): categoryId is keyof typeof getBubblesConfigToCategoryId {
  return categoryId ? categoryId in getBubblesConfigToCategoryId() : false;
}

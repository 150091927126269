export function formatNumberWithMetricPrefix(number: number, lang = 'en') {
  /**
   * Intl.NumberFormat uses CLDR which does not support the Tsd. abbreviation by default
   * https://st.unicode.org/cldr-apps/v#/de/Compact_Decimal_Formatting/ff7ca46dee120b1
   * */
  if (lang === 'de' && number >= 1000 && number < 1000000) {
    return `${Math.trunc(number / 1000)} Tsd.`;
  }

  /**
   * Intl.NumberFormat uses CLDR which does not support the Tsd. abbreviation by default
   * https://st.unicode.org/cldr-apps/v#/it/Compact_Decimal_Formatting/ff7ca46dee120b1
   * */
  if (lang === 'it' && number >= 1000 && number < 1000000) {
    return `${Math.trunc(number / 1000)} MILA`;
  }

  if (lang === 'en' && number < 1000) {
    return number.toString();
  }

  if (number < 1000) {
    return new Intl.NumberFormat(lang).format(number);
  }

  // Add metric prefixes for values over 1000
  return new Intl.NumberFormat(lang, {
    notation: 'compact',
    maximumSignificantDigits: 3,
  })
    .format(number)
    .toUpperCase();
}

export function capitalise(word?: string) {
  if (!word) {
    return '';
  }
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export function hasUpperCase(str: string) {
  return /[A-Z]/.test(str);
}

import React, { useState } from 'react';
import { Text } from '@depop/web-ui-kit/Typography/Text';
import { ArrowDownIcon } from '@depop/web-ui-kit/Icons/ArrowDownIcon';
import { ArrowTopIcon } from '@depop/web-ui-kit/Icons/ArrowTopIcon';

import styles from './styles.module.css';

type Props = {
  depth?: number;
  label: string;
  children: React.ReactNode;
};

export function CollapsibleRow({ label, children, depth }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <button
        className={styles.collapsibleRow}
        style={{ '--depth': depth ?? 0 } as React.CSSProperties}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <Text>{label}</Text>
        {isOpen ? <ArrowTopIcon /> : <ArrowDownIcon />}
      </button>
      {isOpen ? children : undefined}
    </>
  );
}

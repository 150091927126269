import { AxiosCompatibleResponse, RequestConfig } from '../http/types';

import { BrandBySlugResponse, CMSLocales } from './types';

import http from '@/modules/http';
import {
  BRAND_PAGE_BY_SLUG_ENDPOINT,
  CONTENTFUL_ENDPOINT,
} from '@/constants/endpoints';
import { isProduction } from '@/modules/env/isProduction';

export function getBrandPageBySlug(
  slug: string,
  locale: CMSLocales
): Promise<AxiosCompatibleResponse<BrandBySlugResponse>> {
  return http.get(BRAND_PAGE_BY_SLUG_ENDPOINT.replace(':slug', slug), {
    params: { locale },
    cache: 'force-cache',
  });
}

export function getContentfulData<TData = unknown>({
  query,
  cache,
  revalidate = 900,
}: {
  query: string;
  revalidate?: number;
  cache?: RequestConfig['cache'];
}): Promise<AxiosCompatibleResponse<TData>> {
  return http.post(
    CONTENTFUL_ENDPOINT,
    { query },
    {
      revalidate: isProduction() ? revalidate : 0,
      cache,
    }
  );
}

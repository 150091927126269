import { useMemo, useEffect, useState } from 'react';

import { useBrandsById } from '../brands/hooks/useBrandsbyId';
import { buildPopularBrands } from '../brands/utils';
import { Brand } from '../brands/types';
import { useSearchFilterAggregates } from '../search/useSearchFilterAggregates';

export function usePersistedBrandsList({
  sellerId,
}: {
  sellerId?: number;
}): Array<Brand> {
  const { data: aggregates } = useSearchFilterAggregates({
    sellerId,
  });
  const { data: brandData, isLoading: isBrandDataLoading } = useBrandsById();

  const brands = brandData?.data;
  const brandAggregates = aggregates?.data.brands;

  const [initialBrandAggregates, setInitialBrandAttributes] = useState<
    typeof brandAggregates | undefined
  >(undefined);

  useEffect(() => {
    if (!initialBrandAggregates && Boolean(brandAggregates)) {
      /**
       *  store the initial brand aggregates so that the user will
       *  always see the same list of brands, even when they apply a filter
       */
      setInitialBrandAttributes(brandAggregates);
    }
  }, [initialBrandAggregates, brandAggregates]);

  return useMemo(() => {
    if (!brands || !initialBrandAggregates) {
      return [];
    }
    return buildPopularBrands(brands, initialBrandAggregates);
  }, [isBrandDataLoading, initialBrandAggregates]);
}

'use client';

import React, { useEffect } from 'react';
import { useInView, IntersectionOptions } from 'react-intersection-observer';

type Props = {
  children: React.ReactNode;
  onEndReached: () => void;
  hasMore?: boolean;
  loading?: boolean;
  LoadingComponent?: React.ReactNode;
  TriggerComponent?: React.ReactNode;
  observerArgs?: IntersectionOptions;
};

export function InfiniteScroll({
  children,
  onEndReached,
  hasMore,
  loading,
  LoadingComponent,
  observerArgs = {},
}: Props) {
  const { ref, inView } = useInView({ ...observerArgs });

  useEffect(() => {
    if (inView && hasMore) {
      onEndReached();
    }
  }, [inView]);

  return (
    <>
      {children}

      {/** This div is the trigger, as long as there is more
       * content to load, it will be rendered at the bottom of
       * the page, below whatever children are passed in (i.e. a list)
       */}
      {hasMore && <div ref={ref} />}
      {loading && LoadingComponent}
    </>
  );
}

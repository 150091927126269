import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { RQ_FILTER_PRODUCT_ATTRIBUTES_KEY } from '../ReactQuery/cacheKeys';
import { useCurrentLanguage } from '../language/useCurrentLanguage';
import { fetchFilterProductAttributes } from '../search/api';

import { AxiosCompatibleResponse } from '@/modules/http/types';
import { FetchFilterProductAttributesResponse } from '@/modules/search/types';

type ResponseKey = keyof FetchFilterProductAttributesResponse;

export function useFilterProductAttributes({
  attributes = ['condition', 'colour'],
}: {
  attributes?: Array<ResponseKey>;
} = {}): UseQueryResult<
  AxiosCompatibleResponse<Partial<FetchFilterProductAttributesResponse>>
> {
  const { language } = useCurrentLanguage();
  return useQuery({
    select: (
      data: AxiosCompatibleResponse<FetchFilterProductAttributesResponse>
    ) => {
      const dataWithKeysFiltered = Object.entries(data.data).reduce<
        Partial<FetchFilterProductAttributesResponse>
      >((acc, [key, value]) => {
        if (attributes.includes(key as ResponseKey)) {
          (acc[
            key as ResponseKey
          ] as FetchFilterProductAttributesResponse[ResponseKey]) = value;
        }

        return acc;
      }, {});

      return {
        ...data,
        data: dataWithKeysFiltered,
      };
    },
    queryKey: [`${RQ_FILTER_PRODUCT_ATTRIBUTES_KEY}_${language}`],
    queryFn: () => fetchFilterProductAttributes(language),
    refetchOnMount: false,
  });
}

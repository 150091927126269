import React, { useState, useCallback } from 'react';
import { useTranslations } from 'next-intl';
import clsx from 'clsx';
import { CheckboxWithLabel } from '@depop/web-ui-kit/CheckboxWithLabel';
import { LensIcon } from '@depop/web-ui-kit/Icons/LensIcon';
import { Text } from '@depop/web-ui-kit/Typography/Text';
import { useVirtualizer } from '@tanstack/react-virtual';

import { SearchFilterTrigger } from '../SearchFilterTrigger';
import baseFilterStyles from '../baseFilterStyles.module.css';
import { MobileFilterHeader } from '../MobileFilterHeader';
import { MobileFilterFooter } from '../MobileFilterFooter';

import styles from './styles.module.css';

import { Dropdown } from '@/components/Dropdown';
import { useSearchFilters } from '@/modules/search/useSearchFilters';
import { useActivityTracker } from '@/modules/activityTracker/useActivityTracker';
import { useCurrentUser } from '@/modules/user/useCurrentUser';
import { ActivityTrackerEventType } from '@/modules/activityTracker/constants';
import { FilterTypes } from '@/modules/filters/types';
import { useBrandsList } from '@/modules/filters/useBrandsList';
import { usePersistedBrandsList } from '@/modules/filters/usePersistedBrandsList';

type Props = {
  closeMobileFilterDrawer: () => void;
  sellerId?: number;
  showSearchBar?: boolean;
  persistListOnChange?: boolean;
};

export function BrandFilter({
  closeMobileFilterDrawer,
  sellerId,
  showSearchBar,
  persistListOnChange,
}: Props) {
  const {
    searchFilters: { brands },
    setSearchFilters: { setBrandFilter },
  } = useSearchFilters();
  const [searchInputValue, setSearchInputValue] = useState('');
  const [, user] = useCurrentUser();
  const { sendActivityTrackerEvent } = useActivityTracker({ userId: user?.id });

  /**
   * This callback ref workaround is needed to provide the correct ref to
   * react-virtual for an element that does not exist on initial render.
   *
   * See issue: https://github.com/TanStack/virtual/issues/263
   * React docs: https://legacy.reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
   */
  const [virtualizedListRef, setVirtualizedListRef] =
    useState<HTMLDivElement | null>(null);
  const setVirtualizedListRefCallback = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      setVirtualizedListRef(node);
    }
  }, []);

  const commonT = useTranslations('common');
  const searchT = useTranslations('search');

  const dynamicList = useBrandsList({
    searchInputValue,
    sellerId,
  });
  const persistedList = usePersistedBrandsList({ sellerId });
  const brandsList = persistListOnChange ? persistedList : dynamicList;
  const title = commonT('Brand');

  const rowVirtualizer = useVirtualizer({
    count: brandsList.length,
    getScrollElement: () => virtualizedListRef,
    estimateSize: () => 42,
    overscan: 6,
  });
  const virtualizedItems = rowVirtualizer.getVirtualItems();

  return (
    <Dropdown
      TriggerComponent={(props) => {
        return (
          <SearchFilterTrigger
            {...{
              ...props,
              isActive: Boolean(brands?.length),
            }}
            title={title}
          />
        );
      }}
      onOpen={() =>
        sendActivityTrackerEvent(
          ActivityTrackerEventType.FILTER_OPENED_ACTION,
          { filterType: FilterTypes.Brand }
        )
      }
    >
      {({ className, toggleDropdown }) => (
        <div
          className={clsx(
            className,
            baseFilterStyles.searchFilterDropdownContents,
            styles.brandFilterDropdownContents
          )}
        >
          <MobileFilterHeader
            title={title}
            toggleDropdown={toggleDropdown}
            closeMobileFilterDrawer={closeMobileFilterDrawer}
          />
          <div
            className={clsx(
              baseFilterStyles.content,
              styles.brandFilterContent
            )}
          >
            {showSearchBar && (
              <div className={styles.brandFilterSearchContainer}>
                <LensIcon size={16} />
                <input
                  className={styles.brandFilterSearchInput}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setSearchInputValue(e.target.value)
                  }
                  placeholder={searchT('DropdownSearch.Placeholder', {
                    placeholder: commonT('Brands'),
                  })}
                  value={searchInputValue}
                />
              </div>
            )}
            {brandsList.length ? (
              <div
                ref={setVirtualizedListRefCallback}
                className={styles.listContainer}
              >
                <div
                  style={{
                    height: `${rowVirtualizer.getTotalSize()}px`,
                    width: '100%',
                    position: 'relative',
                  }}
                >
                  <div
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      transform: `translateY(${
                        virtualizedItems[0]?.start ?? 0
                      }px)`,
                    }}
                  >
                    {virtualizedItems.map(({ key, index }) => {
                      const brand = brandsList && brandsList[index];
                      const brandId = String(brand?.id);
                      const isChecked = brands?.includes(brandId);

                      if ('isHeading' in brand) {
                        return (
                          <div
                            ref={rowVirtualizer.measureElement}
                            className={styles.brandFilterDropdownHeading}
                            data-index={index}
                            key={key}
                          >
                            {searchT(brand.name)}
                          </div>
                        );
                      }

                      return (
                        <div
                          ref={rowVirtualizer.measureElement}
                          data-index={index}
                          style={
                            {
                              '--font-weight': isChecked ? 'bold' : 'normal',
                            } as React.CSSProperties
                          }
                          key={key}
                        >
                          <CheckboxWithLabel
                            className={clsx(
                              baseFilterStyles.searchFilterCheckbox,
                              styles.brandFilterCheckbox
                            )}
                            label={brand?.name}
                            checked={isChecked}
                            align="left"
                            data-brand-id={brandId}
                            onChange={() => setBrandFilter(brandId)}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            ) : (
              <Text className={styles.brandFilterNoResultsText}>
                {searchT('DropdownSearch.NoResults', {
                  placeholder: commonT('Brands'),
                })}
              </Text>
            )}
          </div>
          <MobileFilterFooter
            handleSubmit={closeMobileFilterDrawer}
            handleClear={() => setBrandFilter()}
            sellerId={sellerId}
          />
        </div>
      )}
    </Dropdown>
  );
}

import React from 'react';
import { useTranslations } from 'next-intl';
import { Button } from '@depop/web-ui-kit/Button';

import styles from '../baseFilterStyles.module.css';

import { useSearchFilterAggregates } from '@/modules/search/useSearchFilterAggregates';

type Props = {
  handleClear: () => void;
  handleSubmit: () => void;
  sellerId?: number;
};

export function MobileFilterFooter({
  handleClear,
  handleSubmit,
  sellerId,
}: Props) {
  const { data } = useSearchFilterAggregates({
    sellerId,
  });
  const t = useTranslations('search');

  return (
    <div className={styles.footer}>
      {!!data?.data.total && t('ResultsCount', { count: data?.data.total })}
      <div className={styles.footerButtonsContainer}>
        <Button
          className={styles.footerButton}
          /**
           * TODO needs ClearFilterAction event:
           * https://depopmarket.atlassian.net/browse/WEB-4976
           */
          onClick={handleClear}
          outline={true}
        >
          {t('ResetAll')}
        </Button>
        <Button className={styles.footerButton} onClick={handleSubmit}>
          {t('ViewItems')}
        </Button>
      </div>
    </div>
  );
}

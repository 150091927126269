import { SearchFilters } from '@/modules/search/useSearchFilters';

function getValueFromList(
  searchFilter?: string[],
  cmsFilter?: string[],
  filtersModified?: boolean
) {
  return searchFilter || (filtersModified ? [] : cmsFilter);
}

function getSingleValue<T>(
  searchFilter?: T,
  cmsFilter?: T,
  filtersModified?: boolean
) {
  return searchFilter || (filtersModified ? undefined : cmsFilter);
}

export function createCombinedFilters(
  filtersFromUrl: SearchFilters,
  cmsFilters: SearchFilters,
  filtersModified: boolean
): SearchFilters {
  return {
    category: getSingleValue(
      filtersFromUrl.category,
      cmsFilters.category,
      filtersModified
    ),
    subcategories: getValueFromList(
      filtersFromUrl.subcategories,
      cmsFilters.subcategories,
      filtersModified
    ),
    brands: getValueFromList(
      filtersFromUrl.brands,
      cmsFilters.brands,
      filtersModified
    ),
    isDiscounted:
      filtersFromUrl.isDiscounted ||
      (filtersModified ? false : cmsFilters.isDiscounted),
    priceMin: getSingleValue(
      filtersFromUrl.priceMin,
      cmsFilters.priceMin,
      filtersModified
    ),
    priceMax: getSingleValue(
      filtersFromUrl.priceMax,
      cmsFilters.priceMax,
      filtersModified
    ),
    sizes: getValueFromList(
      filtersFromUrl.sizes,
      cmsFilters.sizes,
      filtersModified
    ),
    colours: getValueFromList(
      filtersFromUrl.colours,
      cmsFilters.colours,
      filtersModified
    ),
    conditions: getValueFromList(
      filtersFromUrl.conditions,
      cmsFilters.conditions,
      filtersModified
    ),
    sort: getSingleValue(filtersFromUrl.sort, cmsFilters.sort, filtersModified),
    discountTypes: getSingleValue(
      filtersFromUrl.discountTypes,
      cmsFilters.discountTypes,
      filtersModified
    ),
    minDiscount: getSingleValue(
      filtersFromUrl.minDiscount,
      cmsFilters.minDiscount,
      filtersModified
    ),
    maxDiscount: getSingleValue(
      filtersFromUrl.maxDiscount,
      cmsFilters.maxDiscount,
      filtersModified
    ),
  };
}

type Base = {
  id: number;
  name: string;
};

export type SizeFilterBranch = Base & {
  children: Array<SizeFilterBranch> | Array<SizeFilterLeaf>;
};

export type SizeFilterLeaf = Base & {
  composite_id: string;
  variantset_id: number;
};

type SizeFilterNode = SizeFilterBranch | SizeFilterLeaf;

export type SizeFilters = Array<SizeFilterBranch>;

export function childrenAreLeafNodes(
  children: SizeFilterNode[]
): children is SizeFilterLeaf[] {
  const [firstChild] = children as SizeFilterLeaf[];
  return Boolean(firstChild?.composite_id);
}

export function isBranchNode(node: SizeFilterNode): node is SizeFilterBranch {
  return 'children' in node;
}

export type LegacyCategoryFilters = Array<{
  name: string;
  children?: Array<{
    id: number;
    name: string;
  }>;
}>;

export type SubcategoryFilters = Record<
  string,
  Array<{ id: number; name: string }>
>;

export type ActiveFilter = {
  name: string;
  handleClick: (id?: string) => void;
};

export enum FilterTypes {
  Category = 'category',
  Subcategory = 'subcategory',
  Brand = 'brand',
  Size = 'size',
  Price = 'price',
  Colour = 'colour',
  Condition = 'condition',
  Location = 'location',
  OnSale = 'onsale',
}

export enum FilterLocation {
  Mobile = 'mobile',
  Desktop = 'desktop',
}

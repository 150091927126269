import React from 'react';
import clsx from 'clsx';
import { Text } from '@depop/web-ui-kit/Typography/Text';
import { ArrowDownIcon } from '@depop/web-ui-kit/Icons/ArrowDownIcon';
import { ArrowForwardIcon } from '@depop/web-ui-kit/Icons/ArrowForwardIcon';

import styles from '../baseFilterStyles.module.css';

import { TriggerComponentProps } from '@/components/Dropdown';

export function SearchFilterTrigger({
  isOpen,
  onClick,
  isActive,
  title,
  disabled,
}: TriggerComponentProps & { title: string }) {
  return (
    <button
      className={clsx(styles.searchFilterTrigger, {
        [styles.searchFilterTriggerActive]: isActive,
        [styles.searchFilterTriggerOpen]: isOpen,
        [styles.searchFilterTriggerDisabled]: disabled,
      })}
      onClick={onClick}
      disabled={disabled}
    >
      <Text className={styles.searchFilterTriggerText}>{title}</Text>
      <ArrowDownIcon size={16} className={styles.searchFilterTriggerChevron} />
      <ArrowForwardIcon
        size={16}
        className={styles.searchFilterTriggerForward}
      />
    </button>
  );
}
